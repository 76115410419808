.choose-time-wrapper label {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.408px;
    padding: 0;
    color: #5364ff;
    cursor: pointer;
}
.choose-time-wrapper.show-inputs label {
    display: none;
}
.choose-time-wrapper.show-inputs .choose-time-inputs {
    display: flex;
}
.choose-time-inputs {
    display: none;
    flex-wrap: nowrap;
    align-items: center;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.408px;
    padding: 0;
}
.choose-time-inputs input {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.408px;
    padding: 0;
    color: #000;
    margin: 0 5px;
}
