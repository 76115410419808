.auto-btn {
    border: none;
    border-radius: 8px;
    letter-spacing: -0.408px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    margin-right: 10px;
    font-family: 'Fira Sans';
}

.auto-btn:last-child{
    margin-right: 0px;
}

.auto-btn--normal {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    padding: 8px;
}
.auto-btn--xl {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 11.5px 24px;
}

.auto-btn--large {
    font-weight: 600;
    font-size: 15px;
    line-height: 17px;
    padding: 16px 24px;
    letter-spacing: 1px;
}

.auto-btn--gray {
    color: #888e99;
    background: #ecedef;
}
.auto-btn--gray.btn-selected {
    color: #fff;
    background: #5364ff;
}

.auto-btn--blue {
    color: #5364ff;
    background: #ecedef;
    font-weight:500;
}
.auto-btn--blue.btn-selected {
    color: #fff;
    background: #5364ff;
}

.auto-btn--transparent {
    color: #888e99;
    background: transparent;
}
.auto-btn--transparent.btn-selected {
    color: #3b3f49;
    background: #ecedef;
}


.auto-btn--green {
    color: #fff;
    background: #00A99F;
}
.auto-btn--green:disabled{
    background: #C6CAD2;
}
