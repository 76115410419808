.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  height: 3.4rem;
  cursor: pointer;
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-decoration: none;
  box-sizing: border-box;
  border-radius: 8px;
}

.btn svg {
  margin-left: 10px;
  margin-top: 5px;
}

.btn_default {
  background: #ffffff;
  color: #000;
}

.btn_referal {
  background: linear-gradient(90deg, #FF8C22 -0.15%, #FF5D53 99.85%);
  border: 3px solid rgba(255, 255, 255, 0.3);
  color: #fff;
}

.btn_grey {
  background: #ecedef;
  color: #000;
}

.btn_transparent {
  color: #3B3F49;
  background: transparent;
  border: 1px solid #3B3F49;
}

.btn_darkgrey {
  background: #444;
  color: #fff;
  font-size: 1.1rem;
}

.btn_error {
  background: linear-gradient(270deg, #FF8C22 0%, #FF5D53 99.71%);
  color: #fff;
}
.btn_green {
  background: #00BB40;
  color: #fff;
}


.btn_white,.btn_white2 {
  background: #fff;
  color: #444;
  border: 1px solid;
  font-size: 1.1rem;
  font-weight: 400;
}

.btn_white2 {
  border: unset;
  color: #5364FF;
  font-weight: 500;
}

.btn_primary {
  background: #5364ff;
  color: #fff;
}

.btn_large {
  height: 3.6rem;
}

.btn_disable{
  opacity: 0.5;
  border: 1px solid #aaa;
}
