/* Order info styles*/

.order-info {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.408px;
    background-color: #fff;
    border-radius: 8px;
    border: 2px solid transparent;
    transition: 0.3s ease-in-out;
}
.order-info__header {
    position: relative;
    padding: 17px 0 17px 12px;
}
.order-info__header::after {
    position: absolute;
    bottom: 0;
    left: -2px;
    content: "";
    width: calc(100% + 4px);
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
}
.order-info__footer {
    padding: 18px 16px 16px 0;
}

.order-info--disbled {
    color: #888e99;
}
.order-info--disbled .order-header__status {
    border-color: #888e99;
}
.order-info--disbled .order-header__time,
.order-info--disbled .order-footer__status {
    color: inherit;
}
.order-info--disbled .order-footer__image .black,
.order-info--disbled .order-footer__image .blue,
.order-info--disbled .order-footer__image .blue2 {
    fill: #888e99;
}

.order-info--active,
.order-info--active .order-header__status {
    border-color: #5364ff;
}
.order-info--active .order-header__address--from {
    color: #5364ff;
}
.order-info--active .order-footer__image .blue,
.order-info--active .order-footer__image .blue2 {
    fill: #00a99f;
}

.order-info--notActive .order-header__time {
    color: #888e99;
}
.order-info--notActive .order-footer__image .blue {
    fill: #5364ff;
}
.order-info--notActive .order-footer__image .blue2 {
    fill: #3c4ac1;
}

.order-header {
    display: flex;
    align-items: center;
}
.order-header__time {
    margin-right: 12px;
    color: #5364ff;
    white-space: nowrap;
}
.order-header__status {
    margin-right: 12px;
    width: 16px;
    height: 16px;
    border: 4px solid #ff9900;
    border-radius: 50%;
    transition: 0.3s ease-in-out;
}
.order-header__address {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
}
.order-header__address--from {
    display: flex;
    align-items: center;
}
.order-header__address--from::after {
    content: "";
    display: inline-flex;
    width: 6px;
    height: 6px;
    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: #888e99;
    transform: rotate(-45deg);
    margin: 0 16px 0 20px;
}
.order-header__address--text {
    max-width: 130px;
    white-space: nowrap;
}
.order-header__address--to {
}

.order-footer {
    display: flex;
    align-items: center;
}
.order-footer__image {
    flex: 0 0 90px;
}
.order-footer__image .black {
    fill: #000;
    transition: 0.3s ease-in-out;
}
.order-footer__image .blue {
    fill: #888e99;
    transition: 0.3s ease-in-out;
}
.order-footer__image .blue2 {
    fill: #888e99;
    transition: 0.3s ease-in-out;
}
.order-footer__status {
    flex: 1 1 auto;
    color: #5364ff;
    text-align: center;
}
.order-footer__price {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
}

/*Expanded order info styles*/
.expanded-order-info {
    background-color: #fff;
    /*margin: 0 -12px;*/
}
.expanded-order-info__header {
    background: #5364ff;
}
.expanded-order-info__route {
    border-bottom: 1px solid #c6cad2;
}
.expanded-order-info__vehicle {
}
.expanded-order-info__driver {
}
.expanded-order-info__buttons {
    padding: 10px;
    display: flex;
}
.expanded-order-info__buttons button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    flex: 1 1 50%;
    border: none;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #fff;
    cursor: pointer;
}
.expanded-order-info__chat-button {
    border-radius: 8px 0px 0px 8px;
    background: #3b3f49;
}

.expanded-order-info__chat-button.completed {
    border-radius: 8px;
}
.expanded-order-info__chat-button div {
    margin-right: 8px;
}
.expanded-order-info__call-button {
    border-radius: 0 8px 8px 0;
    background: #00bb61;
}
.expanded-order-info__call-button div {
    margin-right: 11px;
}
.expanded-order-info__additional {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
}
.expanded-order-info__additional-item {
    margin: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    background: #ecedef;
    border-radius: 8px;
    padding: 9.5px 12px;
}
.expanded-order-info__tariff {
    padding: 10px 10px 0 10px;
}
.expanded-order-info__cancel-order {
    text-align: center;
    padding: 10px 0 6px;
}
.expanded-order-info__cancel-order-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 328px;
    height: 36px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #888e99;
    border: none;
    background: transparent;
    cursor: pointer;
}

.order-info-header {
    padding: 16px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-weight: 500;
}
.order-info-header__status {
    font-size: 15px;
    line-height: 18px;
}
.order-info-header__time {
    font-size: 14px;
    line-height: 17px;
}

.driver-info {
    padding: 4px 0;
}
.driver-info__row {
    display: flex;
    flex-wrap: nowrap;
    padding: 12px 16px 16px;
}
.driver-info__row p {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 4px;
    color: #888e99;
}
.driver-info__name {
    flex: 1 1 auto;
    font-size: 17px;
    line-height: 20px;
}
.driver-info__rating {
    padding: 0 0 0 13px;
    text-align: right;
    font-size: 17px;
    line-height: 20px;
}
.driver-info__rating--value {
    display: flex;
}
.driver-info__rating--value b {
    margin-left: 4px;
}
.driver-info__passport {
}
.driver-info__license {
    padding-left: 16px;
}
.driver-info__document {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 50%;
    font-size: 15px;
    line-height: 18px;
    color: #000;
}
.driver-info__document svg {
    cursor: pointer;
}
.driver-info__document--txt {
    flex: 1 1 auto;
    margin-left: 16px;
}

.vehicle-info {
}
.vehicle-info__row {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0 12px;
}
.vehicle-info__image {
    flex: 0 0 90px;
}
.vehicle-info__image .black {
    fill: #000;
}
.vehicle-info__image .blue,
.vehicle-info__image .blue2 {
    fill: #00a99f;
}
.vehicle-info__model {
    flex: 1 1 auto;
    margin: 0 10px;
    margin-right: 12px;
    font-size: 14px;
    line-height: 17px;
    color: #888e99;
    text-align: center;
}
.vehicle-info__model p {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #000;
    margin: 0 0 2px 0;
}
.vehicle-info__body-type {
    display: flex;
    align-items: center;
    text-align: right;
    margin-right: 12px;
    font-size: 14px;
    line-height: 17px;
    color: #888e99;
}
.vehicle-info__body-type p {
    font-size: 14px;
    line-height: 17px;
    color: #000;
    margin: 0 0 2px 0;
}
.vehicle-info__body-type--toggler {
    margin-left: 15px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.body-type-active .vehicle-info__body-type--toggler {
    transform: rotate(180deg);
}
.vehicle-info__body-type--dropdown {
    display: none;
    align-items: center;
    justify-content: center;
    padding: 7px 5px;
}
.body-type--dropdown-show {
    display: flex;
}
.vehicle-info__body-prop {
    margin: 5px;
    font-size: 14px;
    line-height: 17px;
    color: #888e99;
    text-align: center;
}
.vehicle-info__body-prop p {
    margin: 0 0 2px 0;
    font-size: 12px;
    line-height: 14px;
}
.vehicle-info__body-options {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 7px 5px 11px 5px;
}
.vehicle-info__body-option {
    margin: 5px;
    background: #00a99f;
    border-radius: 8px;
    padding: 8px;
    color: #fff;
    font-size: 15px;
    line-height: 18px;
}
.vehicle-info__total {
    background: #ecedef;
    border-radius: 8px;
    margin: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 5px;
}
.vehicle-info__total--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
}
.vehicle-info__total--item {
    margin: 0 5px;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    min-width: 97px;
}
.vehicle-info__total--item p {
    margin: 0 0 2px 0;
    font-size: 12px;
    line-height: 14px;
    color: #888e99;
}


.vehicle-info__row.car-description__image svg .black {
    fill: #000;
}
.vehicle-info__row.car-description__image svg .blue {
    fill: #00bb61;
}
.vehicle-info__row.car-description__image svg .blue2 {
    fill: #008b46;
}

.expanded-order-info .address-item__content{
    max-width: 336px;
}

.expanded-order-info__back-link{
    color: rgb(255, 116, 60);
    text-decoration: underline;
    padding: 20px 0px;
    text-align: center;
}
.expanded-order-info__button-container{
    padding: 10px;
}
.expanded-order-info__button{
    width: 100%;
    background-color: #5364ff;
    background-color: #00a99f;
    border: none;
    border-radius: 8px;
    height: 58px;
    color: #fff;
    font-size: 17px;
    font-weight:bold;
    cursor: pointer;
}

.expanded-order-info__button.red-gradient{
    background: linear-gradient(90deg, #ff5d53, #ff6848, #ff743c, #ff8030, #ff8c22);
}

.expanded-order-info__button.violet{
    background-color: #00a99f;
}
