body.modal-show {
    overflow: hidden;
}

.modal-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
}
.modal-wrapper.show-modal {
    display: flex;
}
.modal-container {
    display: flex;
    flex-flow: column nowrap;
    width: 70vw;
    height: 70vh;
    background-color: #fff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding-bottom: 22px;
}
.modal-header {
    padding: 22px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.modal-title {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.408px;
}
.close-modal {
    cursor: pointer;
}
.close-modal rect {
    transition: 0.3s ease-in-out;
}
.close-modal:hover rect {
    fill: #888;
}
.modal-content {
    margin: 0 40px;
    border: 2px solid #c6cad2;
    border-radius: 4px;
    flex: 1 1 auto;
}
