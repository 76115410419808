.list-cargo{
    width: 100%;
    height: auto;
}
.list-cargo__total-items{
    display: flex;
    justify-content: space-between;
    text-align: center;
    padding: 10px 50px 0px 50px;
}
