.inn-container {
    position: relative;

    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    background: #ecedef;
    border-radius: 8px;
    padding-right: 6px;
}
.inn-container div:first-child{
    width:140px;
}
.inn-container div {
      border: none;
  }
.inn-container svg {
      position: absolute;
      right: 0.5rem;
      top: 1rem;
  }
.inn-container span.name {
    width: 100%;
    top: 5px;
    color: var(--grey);

    line-height: 14px;
    max-height: 42px;
    overflow: hidden;

    justify-content: center;
    align-items: center;
    margin-right: 30px;
    text-align: center;
    padding: 5px 0;
    display: block;
  }
.inn-container input{
    padding-right: 0;
    max-width: 140px;
}
.inn-container input:focus,
.inn-container input:active {
    outline: none;
    border: none;
    box-shadow: none;
}


.inn-list{
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    font-family: 'Fira Sans', sans-serif;
    font-size: 17px;
    font-weight: 400;
    background:#fff;
    border: none;
}
.inn-list div {
      border: none;
  }
.inn-list span:last-child {
      color: var(--grey);
}

.inn-input-field {

    width: 100%;
    font-family: 'Fira Sans', sans-serif;
    color: #333;
    background: #fff;
    font-size: 17px;
    font-weight: normal;
    border: none;
    padding: 14px 16px;
}
.inn-input-field:active,
.inn-input-field :focus {
     border: none;
     outline: none;
     box-shadow: none;
 }
