.select-order-mode{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.select-order-mode__button{
    border-radius: 29px;
    width: 145px;
    height: 50px;
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.408px;
    background: #00A99F;
    color: white;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    border: unset;
}

.select-order-mode__button:disabled,.select-order-mode__button.selected:disabled{
    background: #C6CAD2;
}

.select-order-mode__button.selected,
.select-order-mode__button-save{
    background: #5364FF;
}

.select-order-mode__car{
    margin-top: 10px;
}

.select-order-mode__button-save{
    width: 106px;
}

.select-order-mode__button__category{
    display: block;
    opacity: 0.8;
}

.select-order-mode__close_and_save{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.select-order-mode__cancel{
    display: flex;
    /*border: 2px solid;*/
    border-radius: 100px;
    color: #c1b7b7;
    margin-left: 20px;
    height: 50px;
    width: 50px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.cargo-total-items div{
    text-align: center;
}
.cargo-total-items {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 16px 0px;
    background: #ECEDEF;
    border-radius: 8px;
    margin: 10px;
}
