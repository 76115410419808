.drop-down {
    position: relative;
    border-radius: 8px;
    cursor: pointer;
    display: inline-flex;
    margin-right: 10px;
    z-index: 1;
}
.drop-down__toggler {
    width: 12px;
    height: 13px;
    transition: 0.3s ease-in-out;
}

.drop-down--show{
    z-index: 10;
}

.drop-down--show .drop-down__toggler {
    transform: rotate(180deg);

}

.drop-down--center {
    justify-content: center;
}
.drop-down--left {
    justify-content: flex-start;
}
.drop-down--right {
    justify-content: flex-end;
}

.drop-down--normal {
    padding: 8px;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.408px;
}
.drop-down--normal .drop-down__toggler {
    margin-left: 6px;
}
.drop-down--normal .drop-down__item {
    padding: 8px;
}
.drop-down--lg {
    font-weight: 500;
    padding: 9.5px 12px;
}
.drop-down--lg .drop-down__toggler {
    margin-left: 8px;
}
.drop-down--lg .drop-down__item {
    padding: 9px 12px;
}
.drop-down--xl {
    font-weight: 500;
    padding: 16.5px 20px;
}
.drop-down--xl .drop-down__toggler {
    margin-left: 8px;
}
.drop-down--xl .drop-down__item {
    padding: 9px 12px;
}

.drop-down--gray {
    background-color: #ecedef;
    color: #888e99;
}
.drop-down--blue {
    background: #ecedef;
    color: #5364ff;
    font-weight: 500;
}
.drop-down--transparent-green.drop-down-selected{
    color: #00A99F;
    border-bottom: 2px solid #00A99F;
 }
.drop-down-selected{

    color: #fff;
    background: #5364ff;
}

.drop-down--blue .drop-down__toggler svg path {
    stroke: #5364ff;
}
.drop-down--transparent, .drop-down--transparent-green {
    color: #888e99;
    background: transparent;
}

.drop-down--transparent-green{
    border-bottom: 2px solid transparent;
    border-radius: 0;
    padding: 16px 20px;
}

.drop-down__header {
    display: flex;
    align-items: center;
}
.drop-down__container {
    position: absolute;
    top: calc(100% - 6px);
    left: 0;
    min-width: 100%;
    border-radius: 0 0 8px 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    padding-top: 4px;
}
.drop-down__list {
    padding: 0;
    margin: 0;
}
.drop-down__item {
    list-style: none;
    color: #000;
    font-size: 15px;
}

.drop-down__container.fixed{
    position:fixed;
    min-width:unset;
}
