
.question_window{
  width: calc( 100% - 1rem);
  margin: 0 auto;

  min-height: 250px;
  position: fixed;
  background: #ffff;
  border: 1px solid #eee;
  border-radius: 5px;

  box-shadow: 1px 1px 4px #eeee;
  z-index: 1033;
  padding: 20px;
  top: 50%;
  transform: translateY(-50%);
  max-width: 650px;
  /* transform: translateX(-50%); */

  display: block;
  left: 0;
  right: 0;
}

.question_window_buttons{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.question_window_title{
  padding: 40px ;
  font-size: 1.3rem;
  text-align: center;

}


.question_window .btn{
  width: 100px;
}
