.select-car-auto {
    position: fixed;
    left: 410px;
    top: 90px;
    width: 450px;
    max-width: 718px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    font-family: Fira Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.408px;
}


.select-car-auto__types{
    padding: 0  ;
    background: #ECEDEF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px 10px 0 0;
}

.select-car-auto__info{
    color: #888E99;
    padding: 16px 10px;
    border-bottom: 1px solid #C6CAD2;
}


.select-car-auto__input-cargo{
    min-height: 50px;
}


.select-car-auto__list-cargo{
    background: #ECEDEF;
    padding: 16px 10px;
    border-radius: 0 0 10px 10px ;
}

