.journal-order-item{
    background-color: #fff;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 400;
    color: #555;
    margin-bottom: 12px;
    width:100%;
    cursor: pointer;
    position:relative;

}
.journal-order-item:hover{
    box-shadow: 1px 1px 4px #999;
}

.journal-order-item__header{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
}


.journal-order-item__header svg {
    max-width: 16px;
    min-width: 16px;
    margin-left: 10px;
}

.journal-order-item__header-period {
    color: #999;
    font-size: 15px;
    font-weight: 500;
    margin:  17px 12px;
    max-width: 100px;
}

.journal-order-item__header-period.now {
    color: #5364ff
}
.journal-order-item__header-address{
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 10px 0 12px;
}


.journal-order-item__content-status{
    color: #888e99;
    font-size: 15px;
    font-weight: 500;
}

.journal-order-item__content-status.now_or_completed{
    color: #5364ff;
}
.journal-order-item__content-status.canceled{
    color:  #ff3131;
}

.journal-order-item__content{
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 20px 0;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.journal-order-item__content-amount{
    font-size: 20px;
    font-weight: 700;
    color: #000;
}
.journal-order-item__content-img.car-description__image{
    margin-bottom: unset;
}

.journal-order-item__loading{
    position:absolute;
    left:0;
    top:0;
    display:flex;
    justify-content:center;
    align-items: center;
    width:100%;
    height:100%;
    opacity:0.8;
    background:#fff;
}
