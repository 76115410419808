.map {
  position: fixed;
  width: 100vw;
  height: 100vh;

  .iconContainer {
    margin-top: -98px;
    margin-left: -100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    .markerNumber {
      z-index: 5;
      background: #fff;
      padding: 5px;
      font-size: 12px;
      border-radius: 7px;
      min-width: 23px;
      text-align: center;
      white-space: nowrap;
    }
  }
}
