.rowItem {
    padding: 11px 6px 11px 42px;
    white-space: nowrap;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.408px;
    color: #000000;
}

.rowItem:hover {
    background: #ccc;
    cursor: pointer;
}

.selectedAddress {
    background: #ccc;
}

.listAutoSuggest {
    max-height: 300px;
    min-width: 100%;
    display: block;
    overflow: auto;
    position: absolute;
    z-index: 100;
    background: #fff;
    padding: 6px 0;
    top: 47px;
    left: 0;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 8px 8px;
    width: unset !important;
}

.listAutoSuggestHide {
    display: none;
}

.autoSuggestValue {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 25px rgb(0 0 0 / 10%);
    padding: 0.9rem;
}

.autoSuggestValue input {
    border: unset;
    padding: 2px 10px;
    width: calc(100% - 1.8rem);
    outline: none;
    /* font-size: 1rem !important; */
}
