.register-window {
    letter-spacing: -0.408px;
    background-color: #fff;
}
.register-window__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    padding: 0 24px;
    margin: 35px 0;
}
.register-window__form {
}
.registration-form {
    padding: 5px;
}
.registration-form__item {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
}
.registration-form__input {
    width: 100%;
}
.registration-form__input--phone {
    flex: 0 0 100%;
}

.registration-form__input--code {
    width:50%;
}

.registration-form__input--hidden{
    display:none;
}
.registration-form__code-note {

    text-align: center;
    font-size: 14px;
    line-height: 17px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #888e99;
    width: 100%;
    flex: 1 1 100%;
}
.registration-form__code-note span {
    cursor: pointer;
}
.registration-form__item--show-code {
}
.registration-form__item--show-code .registration-form__input--phone {
    flex: 0 0 50%;
}
.registration-form__item--show-code .registration-form__input--code {
    flex: 0 0 50%;
    position: relative;
    right: auto;
}
.registration-form__item--code-error .registration-form__code-note {
    display: block;
}
.registration-form__item--inn {
    margin-top: 30px;
}
.registration-form__note {
    text-align: center;
    font-size: 14px;
    line-height: 17px;
    margin-top: 5px;
    color: #888e99;
    width: 100%;
}
.registration-form__agreements {
    margin-top: 30px;
}
.registration-form__button--wrapper {
    margin-top: 30px;
}
.registration-form__button {
    width: 100%;
    background: #c6cad2;
    border-radius: 8px;
    height: 56px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #fff;
    border: none;
    font-family: "Fira Sans";
    cursor: pointer;
}
.registration-form__button--content {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.registration-form__button--content div {
    margin-right: 12px;
    height: 24px;
}
.registration-form__button--active {
    background: rgb(255, 140, 34);
    background: -moz-linear-gradient(270deg, rgba(255, 140, 34, 1) 0%, rgba(255, 93, 83, 1) 100%);
    background: -webkit-linear-gradient(270deg, rgba(255, 140, 34, 1) 0%, rgba(255, 93, 83, 1) 100%);
    background: linear-gradient(270deg, rgba(255, 140, 34, 1) 0%, rgba(255, 93, 83, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff8c22",endColorstr="#ff5d53",GradientType=1);
}

.registration-form__item.registration-form__item--inn{
    overflow: unset;
}
