.active, .activeDate {
  border-radius: 8px;
  border: 2px  solid #333;
  color: #333;

}

.newOrderHeaderWrapper {
  padding: 12px 6px 12px 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px 10px 0 0;
  background: #fff;
  border-bottom: 1px solid #C6CAD2;
}

.newOrderHeaderWrapper button {
  margin-left: 10px;
  height: 34px;
  box-sizing: border-box;
  background: #FFF;
  border: none;
  color: #888E99;
  padding: 8px;
  font-weight: 600;
  cursor: pointer;
}
.newOrderHeaderWrapper .nowrap{
  white-space: nowrap;
  letter-spacing: -0.41px;
}


