.paymentMethod {
    min-height: 140px;
    background: #FFFFFF;
    border-top: 1px solid #888E99;
}
.paymentButtons {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.408px;
}
.paymentButtons div {
    padding: 10px;
    cursor: pointer;
}
.paymentButtons .selected {
    background: #5364FF;
    border-radius: 8px;
    color:#FFFFFF;
}
.paymentButtons .unselected {
    color: #888E99;
}

.paymentData {
    padding: 10px;
}
.paymentData .card {
    width: 100%;
    height: 50px;
    border: none;
    background: #ECEDEF;
    border-radius: 8px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 0 16px;
    color: #5364FF;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.408px;
}
.paymentData div[hidden] {
    display: none !important;
}
.INN {
    padding: 0 6px;
}

.paymentData input {
    width: 100%;
    height: 50px;
    border: none;
    background: #ECEDEF;
    border-radius: 8px;
    padding: 0 16px;
    outline: none;
}
.paymentData .companyName{
    margin-top: -50px;
    margin-left: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    overflow: hidden;
}

.cash {
    min-height: 50px;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.408px;
    color: #5364FF;
}

