.address-point__item {
}
.address-item {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 12px 19px 12px 14px;
    min-height: 50px;
    transition: 0.3s ease-in-out;
}

.address-item--full {
    border-bottom: 1px solid #C6CAD2;
}


.address-item--to .address-item__input input::-webkit-input-placeholder,
.address-item--from .address-item__input input::-webkit-input-placeholder{
    color:#fff !important;
}
.address-item--to .address-item__input input:-moz-placeholder,
.address-item--from .address-item__input input:-moz-placeholder {
    color: #fff !important;
}
.address-item--to .address-item__input input:-ms-input-placeholder,
.address-item--from .address-item__input input:-ms-input-placeholder {
     color:#fff !important;
}

.address-item--to.focused  .address-item__input input::-webkit-input-placeholder,
.address-item--from.focused .address-item__input input::-webkit-input-placeholder
{
    color:#999 !important;
}

.address-item--to.focused  .address-item__input input::-moz-placeholder,
.address-item--from.focused .address-item__input input::-moz-placeholder{
    color:#999 !important;
}
.address-item--to.focused  .address-item__input input::-ms-input-placeholder,
.address-item--from.focused .address-item__input input::-ms-input-placeholder{
    color:#999 !important;
}


.address-item--from {
    background-color: #ff9900;
}
.address-item--to {
    background-color: #5364ff;
}
.focused {
    background-color: #ECEDEF;
}

.address-item--newbg {
}
.address-item--newbg.address-item--from {
    background-color: #ffffff;
}
.address-item--newbg.address-item--to {
    background-color: #ecedef;
}
.address-item--newbg.address-item--from .address-item__icon {
    border-color: #ff9900;
}
.address-item--newbg.address-item--to .address-item__icon {
    border-color: #5364ff;
}

.address-item--empty {
}
.address-item--empty .address-item__time {
    display: none;
}
.address-item--empty .address-item__icon {
    border-color: #ffffff;
}
.address-item--empty .address-item__toggler {
    display: none;
}
.address-item--from.address-item--empty .address-item__toggler svg path {
    stroke: #ffffff;
}

.address-item--error .address-item__time {
    display: none;
}
.address-item--error .address-item__error {
    display: block;
}

.address-item--info {
}
.address-item--info .address-item__icon {
    border-color: #5364ff;
}

.address-item__icon {
    padding-right: 10px;
    padding-left: 0px;
}
.address-item__content {
    flex: 1 1 auto;
    max-width: 320px;
}
.address-item__input-info{
    color: #888E99;
    margin-top: -5px;
    cursor: pointer;
}
.address-item__input {
}

.input-wrap__input::-webkit-input-placeholder{ color: #aaa !important; }
.input-wrap__input:-moz-placeholder{ color: #aaa !important; }
.input-wrap__input::-moz-placeholder{ color: #aaa !important; }
.input-wrap__input:-ms-input-placeholder { color: #aaa !important; }
.input-wrap__input::-ms-input-placeholder  {   color: #aaa !important; }
.input-wrap__input::placeholder{ color: #888e99 !important; }
.address-info__row input::placeholder{ color: #888e99 !important; }

.address-item__input input {
    border: none;
    background: none;
    color: #000;
    font-family: "Fira Sans";
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.408px;
    padding: 0;
    width: 100%;
}
.address-item__input input:focus {
    outline: none;
}
.address-item__input input::placeholder {
    color: #fff;
    font-weight: normal;
}
.address-item__time {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.408px;
    color: #888e99;
    margin-top: 4px;
}
.address-item__error {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.408px;
    color: #ff3131;
    margin-top: 4px;
    display: none;
}
.address-item__toggler {
    width: 12px;
    height: 13px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    margin-left: auto;
    padding-left: 5px;
}

.address-item__expand-icon {
    width: 20px;
    text-align: center;
    margin-top: -15px;
    margin-right: -10px;
    cursor: pointer;
}
.address-item__tooltip {
    position: absolute;
    width: 100%;
}

.dropdown-show .address-info {
    display: block;
}
.dropdown-show .address-item__toggler {
    transform: rotate(180deg);
}
.address-item__dropdown {
    flex: 1 1 100%;
    padding-top: 12px;
}
.address-info {
    display: none;
}
.address-info__row {
    padding: 11px 0 11px 2px;
}
.address-info__row input {
    border: none;
    background: none;
    color: #000;
    font-family: "Fira Sans";
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.408px;
    padding: 0;
    width: 100%;
}
.address-info__row input:focus {
    outline: none;
}
.address-info__row input::placeholder {
    color: #5364ff;
    font-family: "Fira Sans";
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.408px;
}
.address-info__row input[type="time"] {
    width: auto;
    height: 18px;
}

.address-info__row .unchanged {
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.408px;
    color: #5364FF;
}

.address-info__row .changed {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.408px;
    color: #888E99;
}
.address-info__row .text {
    font-family: "Fira Sans", sans-serif;
    word-wrap: break-word;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.408px;
}

.address-info__phone {
}
.address-info__contact-person {
}
.address-info__time {
    display: flex;
}
.address-info__working-hours {
    flex: 0 0 50%;
}
.address-info__lunch {
    flex: 0 0 50%;
}
.address-info__from-to {
    display: flex;
}
.address-info__sender {
    flex: 0 0 50%;
}
.address-info__addressee {
    flex: 0 0 50%;
}
.address-info__what-to-do {
}
.address-info__delete {
    padding: 0px 10px 0 10px;
    text-align: center;
}
.address-info__delete-button {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.408px;
    color: #ff3131;
    border: none;
    background: none;
    padding: 16px;
    cursor: pointer;
}

.empty-address-point{
    padding: 12px 19px 12px 14px;
    min-height: 50px;
    color:#888E99;
    display: flex;
    cursor: pointer;
    border-bottom: 1px solid #C6CAD2;
}

.empty-address-point .address-item__icon{
    border-color: #C6CAD2;
}


.address-info-label{
    font-size: 17px;
    line-height: 18px;
    letter-spacing: -0.408px;
    color: #5364ff;
    cursor: pointer;
    padding: 11px 0 11px 0px;
}
