.input-cargo__button{
    padding: 16px 10px;
}

.input-cargo__size{
    padding: 16px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
