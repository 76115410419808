.overlay {
  position: fixed;
  top: 0;
  left: 0;

  background: #000;
  opacity: 0.5;

  height: 100vh;
  width: 100vw;

  z-index: 35;
}

.hidden_overlay{
  display: none;
  visibility: hidden;
}
