.cargo-item{
    display: flex;
    width: 100%;
    margin: 5px 0;
    font-family: Fira Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.408px;

}
.cargo-item__size.selected{
    border: 2px solid #00A99F;
}
.cargo-item__size{
    height: 60px;
    background: #FFFFFF;
    border: 2px solid transparent;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 10px;
    cursor: pointer;
}

.cargo-item__quantity,.cargo-item__delete{
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    letter-spacing: -0.408px;
    font-weight: 500;
}

.cargo-item__delete{
    cursor: pointer;
}

.cargo-item__size-title{
    color: #888E99;
    font-size: 13px;
    padding-bottom: 2px;
    text-align: center;
}

.cargo-item__size-title.right{
      text-align: right;
}

.cargo-item__size-title.left{
    text-align: left;
}
