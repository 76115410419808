.journal-window{
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 500;
    height:100%;
    display:flex;
    flex-direction:column;
    overflow-y: auto;
}
.journal-window-title{
    padding:37px 24px;
    font-size: 21px;
    line-height: 25px;
    letter-spacing: -0.408px;

    color: #000000;
}

.journal-window-menu{
    display: flex;
    justify-content: space-between;
    padding:12px
}



.journal-window-menu-item{
    width:31%;
    font-size: 15px;
    line-height: 17px;
    color: #888E99;
    text-align: center;
    letter-spacing: -0.408px;
    padding:10px 0;
    cursor: pointer;
}

.journal-window-menu-item:hover{
    box-shadow: 1px 1px 4px #999;
    border-radius: 8px;
}

.journal-window-menu-item.selected{
    background: #ECEDEF;
    border-radius: 8px;
    color: #000;
}

.journal-window-date-separator{
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.408px;
    color: #888E99;
    padding-top:10px;
    padding-bottom:20px;
}
.journal-window-content{
    height:100%;
    background: #ECEDEF;
    width:100%;
    padding:12px;
    overflow-y:scroll;
}

.journal-order-list__empty{
    text-align: center;
    padding-top: 50px;
    font-size: 16px;
    color: #959595;
    text-shadow: 1px 1px 1px #fff;
}
