.additionalRequirements {
    min-height: 64px;
    background: #FFFFFF;
    border-top: 1px solid #bfbfbf;
    padding: 10px ;
    display: flex;

    align-items: center;
    overflow-x: auto;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.408px;

    justify-content: space-between;
    flex-wrap: wrap;

}

.additionalRequirements .aditionalRequirement {
    background: #ECEDEF;
    border-radius: 8px;
    padding: 10px;
    white-space: nowrap;
    margin-left: 10px;
    color: #888E99;
}

.additionalRequirements .auto-btn {
    margin-right: unset;    min-width:150px;

}
.additionalRequirements .selectedRequirement {
    background: #5364FF;
    color: #FFFFFF;
}

.additionalRequirements .auto-btn,
.additionalRequirements .drop-down {
    margin: 10px 0px

}

.additionalRequirements .drop-down {
    min-width:150px;
    justify-content: center;
}
