.tariff-item {
    margin: 3px 0;
    background: #ecedef;
    border-radius: 8px;
    letter-spacing: -0.408px;
}

.tariff-item--show-dropdown .tariff-item__dropdown {
    display: block;
}
.tariff-item--show-dropdown .tariff-header__dropdown-toggler svg {
    transform: rotate(180deg);
}

.tariff-item--selected .tariff-item__header {
    background-color: #5364ff;
    border-radius: 8px;
}
.tariff-item--selected .tariff-header__name,
.tariff-item--selected .tariff-header__price {
    color: #fff;
}
.tariff-item--selected .tariff-header__url a,
.tariff-item--selected .tariff-header__dropdown {
    color: #fff;
    opacity: 0.6;
}
.tariff-item--selected .tariff-header__dropdown-toggler svg path {
    stroke: #fff;
}
.tariff-item--selected .tariff-header__dropdown {
    display: flex;
}

.tariff-item--show-dropdown.tariff-item--selected .tariff-item__header {
    background-color: transparent;
    border-radius: 0px;
}
.tariff-item--show-dropdown.tariff-item--selected .tariff-header__name,
.tariff-item--show-dropdown.tariff-item--selected .tariff-header__price {
    color: #000;
}
.tariff-item--show-dropdown.tariff-item--selected .tariff-header__url a,
.tariff-item--show-dropdown.tariff-item--selected .tariff-header__dropdown {
    color: #5364ff;
    opacity: 1;
}
.tariff-item--show-dropdown.tariff-item--selected .tariff-header__dropdown-toggler svg path {
    stroke: #5364ff;
}

.tariff-item__header {
    display: flex;
    background-color: transparent;
    transition: 0.3s ease-in-out;
}
.tariff-item__dropdown {
    display: none;
}

.tariff-header {
    padding: 10px;
    cursor: pointer;
}
.tariff-header__check {
    margin-right: 14px;
    cursor: pointer;
}
.tariff-header__check-box {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    margin: 0;
}
.tariff-header__check-box:checked + .tariff-header__check-icon {
    border-color: #fff;
    background-color: #fff;
}
.tariff-header__check-box:checked + .tariff-header__check-icon svg path {
    stroke: #5364ff;
}
.tariff-header__check-icon {
    width: 20px;
    height: 20px;
    border: 2px solid #c6cad2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease-in-out;
}
.tariff-header__check-icon div {
    display: flex;
}
.tariff-header__check-icon svg {
    width: 11px;
}
.tariff-header__check-icon svg path {
    stroke: #ecedef;
    transition: 0.3s ease-in-out;
}
.tariff-header__description {
    flex: 1 1 auto;
}
.tariff-header__name {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    padding-top: 1px;
    transition: 0.3s ease-in-out;
}
.tariff-header__url {
    margin-top: 5px;
    margin-bottom: 1px;
}
.tariff-header__url a {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #5364ff;
    transition: 0.3s ease-in-out;
}
.tariff-header__price {
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    text-align: right;
}
.tariff-header__dropdown {
    display: flex;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    cursor: pointer;
    margin-top: 4px;
    color: #5364ff;
    display: none;
    align-items: center;
}
.tariff-header__dropdown-toggler {
    margin-left: 4px;
    display: flex;
}
.tariff-header__dropdown-toggler svg {
    transition: 0.3s ease-in-out;
}
.tariff-header__dropdown-toggler svg path {
    stroke: #5364ff;
}

.tariff-content {
}
.tariff-content__item {
}
.tariff-content__header {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #fff;
    background: #5364ff;
    padding: 8px 12px;
}
.tariff-content__list {
    margin: 0;
    padding: 0;
}
.tariff-content__list-item:not(:last-child) {
    border-bottom: 1px solid #c6cad2;
}
.tariff-content__list-item {
    list-style: none;
    display: flex;
    padding: 12px;
    align-items: center;
}
.tariff-content__list-item--description {
    flex: 1 1 auto;
}
.tariff-content__list-item--legend {
    font-size: 13px;
    line-height: 16px;
    color: #888e99;
    margin-bottom: 2px;
}
.tariff-content__list-item--price {
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
}

.tariff-content__list--extended {
}
