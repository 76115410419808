.pack-cargo{
    position: fixed;
    background: #fff;
    /* float: left; */
    top: 90px;
    left: 870px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 25%);
    border-radius: 12px;
    width: 415px;
}

.pack-cargo__category_and_schema{
    padding-top: 12px;
    display: flex;
    justify-content: flex-start;
}

.pack-cargo__category{
    color: #fff;
    background: #00A99F;
    padding: 10px;
    border-radius: 8px;
    text-align: left;
    font-family: Fira Sans;
    letter-spacing: -0.408px;
    margin-left: 10px;
    margin-right: 10px;
}

.pack-cargo__category-weight{
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
}

.pack-cargo__category-info{
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.408px;
    color: #888E99;
    text-align: center;
}

.select-car__btns button{
    margin: 5px;
}

.select-car__chrts{
    padding: 0 10px;
}


.size-block {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: #C6CAD2;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 3px;
    margin: 2px;
}
.size-block__value{
    font-size: 11px;
    font-family: cursive;
    font-weight: 100;
}
.size-block__value:nth-child(2),
.size-block__value:nth-child(4){
    position: absolute;
    top: calc(50% - 9px);
    transform: rotate(-90deg);
}
.size-block__value:nth-child(2) {
    right: 5px
}

.size-block__value:nth-child(4) {
    left: 5px
}

.cargo__sizes {
    width: 170px;
    box-sizing: border-box;
    border: 2px solid #C6CAD2;
    border-radius: 4px;
    height: 94px;

    position: absolute;
    max-width: 280px;
    overflow: hidden;
}

.cargo__size-block {
    position: absolute;
    box-sizing: border-box;
    padding: 1px;
    border: unset;
}

/*.sizes-block::-webkit-scrollbar {*/
/*    height:3px;*/
/*    width:3px*/
/*}*/
.sizes-block {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
