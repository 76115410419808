.select-car {
    position: fixed;
    left: 410px;
    top: 90px;
    width: 100%;
    max-width: 718px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
}
.select-car__list {
    padding: 10px 0 0 10px;
    display: flex;
    flex-wrap: wrap;
}
.select-car__btns {
    padding: 0 10px 10px 10px;
}

.select-car__btns:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.select-car__chrts{
    display: flex;
    flex-wrap: wrap;
}

.select-car__chrts .auto-btn, .select-car__chrts .drop-down{
    margin-right: 10px;
    margin-bottom: 10px;
}
