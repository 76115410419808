
.react-dadata__suggestion {
    background: #fff !important;
    color: #000 !important;
}

.react-dadata__suggestions {
    border: 2px solid #ecedef;
    box-shadow: 0px 15px 5px #00000020;
    border-radius: unset;
    display: flex;
    flex-direction: column;
    width:362px;
    margin-left: unset;
    margin-top:-15px;
}

.react-dadata__suggestion:hover {
    background-color: rgba(0,124,214,.1) !important;
}

.react-dadata__suggestion{
    height: auto !important;
}


