

.burger-menu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;

    position: absolute;
    width: 40px;
    height: 40px;
    left: 420px;
    top: 20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
}

.burger-menu-registration{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px  24px;

    position: absolute;
    width: 135px;
    height: 40px;
    left: 470px;
    top: 20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 37px;
    text-transform:uppercase;
    cursor: pointer;
}


.burger-menu-registration:hover,
.burger-menu:hover{
    opacity:0.85;
}
