.create-order__form {
    position: absolute;
    width: 400px;
    left: 0px;
    top: 0px;
    bottom: 0px;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    max-height: 100vh;
    overflow-y: auto;
}

.create-order__form::-webkit-scrollbar-track
{
    border: 1px solid black;
    background-color: #F5F5F5;
}

.create-order__form::-webkit-scrollbar
{
    width: 5px;
    background-color: #F5F5F5;
}

.create-order__form::-webkit-scrollbar-thumb
{
    background-color: #ff9900;

}
