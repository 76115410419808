.custon-checkbox {
    padding: 6px;
}
.custon-checkbox__input {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
}
.custon-checkbox__label {
    display: block;
    position: relative;
    padding-left: 33px;
}
.custon-checkbox__check {
    display: flex;
    position: absolute;
    margin-left: -33px;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 4px;
    width: 20px;
    height: 21px;
    border: 1px solid #c6cad2;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.custon-checkbox__input:checked + .custon-checkbox__check {
    background: #5364ff;
    border-color: #5364ff;
}
.custon-checkbox__text {
    font-size: 15px;
    line-height: 20px;
    color: #000;
}
.custon-checkbox__text a {
    color: #000;
    text-decoration: underline;
}
.custon-checkbox__text a:hover {
    text-decoration: none;
}
