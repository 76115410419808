.validate-input {
    padding: 5px;
}
.validate-input__input-wrapper {
    position: relative;
}
.validate-input__input {
    background: #ecedef;
    border-radius: 8px;
    border: none;
    width: 100%;
    min-height: 50px;
    padding: 14px 33px 14px 16px;
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
}
.validate-input__input:focus {
    outline: none;
}
.validate-input__input::placeholder {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #888e99;
}
.validate-input__input[type="number"]::-webkit-outer-spin-button,
.validate-input__input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.validate-input__input[type="number"],
.validate-input__input[type="number"]:hover,
.validate-input__input[type="number"]:focus {
    appearance: none;
    -moz-appearance: textfield;
}
.validate-input__error-message {
    font-size: 14px;
    line-height: 20px;
    color: #ff3131;
    text-align: center;
    margin-top: 6px;
    display: none;
}
.validate-input__icon {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
}
.validate-input__icon.show-icon {
    display: block;
}
.validate-input__inn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
}

.show-err {
    display: block;
}

.react-dadata__suggestions {
    margin: 0;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 8px 8px;
    border: none;
    left: 0;
    top: 42px;
    width: 100%;
    padding: 6px 0;
}
.react-dadata--highlighted {
    color: #5364ff;
}
