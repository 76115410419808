.background-container {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  .form-container {
    position: fixed;
    left: calc(50% - 300px);
    top: 200px;
    width: 600px;
    min-height: 180px;
    height: auto;
    background: #ffffff;
    border: 1px solid #aaaaaa;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around; }

  .message-text {
    font-family: Arial, sans-serif;
    font-size: 21px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    color: #535353; }

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-top: 10px; }

  .button {
    width: 160px; }

  .button.active {
    padding: 10px;
    background: orange;
    border-radius: 10px;
    color: #fff;
    font-weight: 500;
    font-family: 'Fira Sans';
    font-size: 16px;
    margin-top: 10px;
    cursor: pointer;
    border: unset; } }




.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px; }


.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #FFB700;
  animation-timing-function: cubic-bezier(0, 1, 1, 0); }

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite; }

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite; }

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0); } }
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(24px, 0); } }





