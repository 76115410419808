.label-input{
    font-family: Fira Sans;
    font-style: normal;
    font-weight: normal;
    width: 76px;
    height: 60px;
    background: #ECEDEF;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.label-input.white-bg{
    background: #fafafa;
}

.label-input input{
    background:transparent;
    text-align: center;
    border: unset;
    width: 100%;
    padding: 0 5px ;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.408px;
    outline: unset;
}


.label-input__title{
    font-family: Fira Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.408px;
    text-align: center;
}

.label-input__title.small_title{
    font-size: 13px;
    line-height: 16px;
    margin-top: -10px;
    padding-bottom: 3px;
    color: #888E99;
}
