*, *::before, *::after {
	box-sizing: border-box;
}

body {
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
}

#root {
	flex-grow: 1;
}

a {
	text-decoration: none;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

::placeholder {
	font-family: 'Fira Sans';
	font-size: 1.1rem;
	font-weight: 400;
	color: #888e99;
	opacity: 1;
}

.small::placeholder {
	font-size: 0.9rem;
}

.inactive {
	opacity: 0.5;
}

.error {
	border-width: 2px!important;
	border-style: solid!important;
	border-color: red!important;
}

.react-dadata__suggestions {
	position: absolute;
	top: 3rem;
	left: calc(-0.8rem - 2px);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: calc(100% + 4px);
	margin: 0 0.8rem;
	background-color: #fff;
	border-right: 2px solid #5364ff;
	border-left: 2px solid #5364ff;
	border-bottom: 2px solid #5364ff;
	padding: 0.67rem 0;
	border-radius: 0 0 0.5rem 0.5rem;
	z-index: 10;
}

.react-dadata__suggestion {
	width: 100%;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 1.1rem;
	color: #000;
	background: #fff;
	border: none;
	border-bottom: 1px solid #5364ff;
	text-align: left;
	padding: 0 0.5rem;
}

.react-dadata__suggestion:last-child {
	border: none;
}

.react-dadata__small {
	font-size: 1rem;
	padding: 0.5rem;
}

.react-dropdown-select {
	border: none!important;
	padding-left: 0.75rem!important;
}

.react-dropdown-select-content {
	font-family: 'Fira Sans';
	font-size: 1.1rem;
	font-weight: 400;
	line-height: 3.33rem;
	color: #000;
	padding-left: 0.2rem!important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.react-dropdown-select-input {
	width: 100%!important;
}

.react-dropdown-select-dropdown-handle {
	margin-right: 0.75rem!important;
}

.react-dropdown-select-item {
	font-family: 'Fira Sans';
	font-size: 1.1rem;
	line-height: 1.1rem;
	font-weight: 400;
	color: #000;
	padding-left: 1rem!important;
	padding-right: 1rem!important;
	white-space: nowrap;
}

.react-dropdown-select-item-selected {
	background: #5364ff!important;
}

.react-date-picker__wrapper {
	border: none!important;
	margin: 0 1rem;
}

.react-date-picker__wrapper * {
	font-family: 'Fira Sans';
	font-size: 1.1rem;
	line-height: 1.1rem;
	font-weight: 400;
	color: #000;
}
