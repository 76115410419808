.menu-form{
    position: absolute;
    width: 400px;
    left: 0px;
    top: 0px;
    bottom: 0px;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    max-height: 100vh;
    overflow-y: auto;
}



.menu-form-user-info{
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #000000;
}

.menu-form-user-info__subtitle{
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #888E99;
    margin: 2px 0px;
}



.menu-form-item:hover{
    background:#f5f5f5;
}
.menu-form-item{
    padding-left:24px;
    padding-top:23px;
    padding-bottom:23px;
    cursor: pointer;
}

.menu-form-logo:hover{
    background:unset;
}
.menu-form-logo{
    padding-top:24px;
}


.menu-form-arrow-right{
    position: absolute;
    right: 24px;
    top: 6px;
}
.menu-form-item-info{
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    position: relative;
}

.menu-form-close{

    position: fixed;
    left: 420px;
    top: 20px;
    cursor: pointer;
}
.profile-form-back{
    background:#fff;
    padding: 10px;
    border-radius: 58px;
    padding-top: 9px;
    padding-bottom: 7px;
    box-shadow: 3px 2px 5px #00000020;
}
.menu-form-item-button__container{
    padding:10px;
}
.menu-form-item-button:hover{
    opacity: 0.8;
}
.menu-form-item-button{
    padding: 12px 16px 12px 24px;
    width: 100%;
    text-align: center;
    font-size: 17px;
    background: #ECEDEF;
    border-radius: 12px;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
    color:#333;
}


.menu-form{

}

/* width */
.journal-window-content::-webkit-scrollbar,
.menu-form::-webkit-scrollbar{
    width: 5px
}

            /* Track */
.journal-window-content::-webkit-scrollbar-track,
.menu-form::-webkit-scrollbar-track {
    background: #fff
}

            /* Handle */
.journal-window-content::-webkit-scrollbar-thumb,
.menu-form::-webkit-scrollbar-thumb {
    background: #FFB700
}

                         /* Handle on hover */
.journal-window-content::-webkit-scrollbar-thumb:hover,
.menu-form::-webkit-scrollbar-thumb:hover {
    background: #FFB700
}

.journal-window-content::-webkit-scrollbar,
.menu-form::-webkit-scrollbar {
    width: 5px
}

.menu-form-item__border_bottom{
    border-bottom:1px solid #f0f0f0;
}
