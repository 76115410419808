.createOrder {
    min-height: 122px;
    padding: 12px 24px;
}
.createOrder .errorMessage {
    color: #FF3131;
    width: 100%;
    text-align: center;
    margin-bottom: 16px;
}
.createOrder button {
    width: 100%;
    height: 60px;
    border: none;
    background: linear-gradient(270deg, #FF8C22 0%, #FF5D53 99.71%);
    border-radius: 8px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.408px;
    font-family: "Fira Sans", sans-serif;
}

.createOrder .creatingOrder{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: unset;
    margin-top: 0px;
    color: #888;
}

.createOrder .disabledOrderButton {
    background: #ECEDEF;
    color: #C6CAD2;
}
.createOrder .linkWrapper{
    color: #ff743c;
    text-decoration: underline;
    padding: 20px 0 ;
    text-align: center;
}
