.car-card {
    display: flex;
    margin: 0 10px 10px 0;
    padding: 0 6px 6px 0;
    background: #ecedef;
    border-radius: 8px;
    flex: 0 0 222px;
    cursor: pointer;
}
.car-card__description {
    flex: 0 0 116px;
}
.car-card__info {
    flex: 1 1 auto;
}
.car-card__selected {
    background: #5364ff;
    color: #fff;
}
.car-card__selected .car-description__image svg .blue,
.car-card__selected .car-description__image svg .blue2 {
    fill: #fff;
}
.car-card__selected .car-info__legend {
    color: #fff;
}
.car-card__selected .car-info__value {
    color: #000;
}
.car-card__disabled {
    background: transparent;
    color: #888e99;
}
.car-card__disabled .car-info__legend {
    color: #888e99;
}
.car-card__disabled .car-info__value {
    color: #888e99;
    background: #ecedef;
}
.car-card__disabled .car-description__image svg .black,
.car-card__disabled .car-description__image svg .blue,
.car-card__disabled .car-description__image svg .blue2 {
    fill: #888e99;
}

.car-description {
    padding-top: 14px;
}
.car-description__image {
    margin-bottom: 10px;
}
.car-description__image svg .black {
    fill: #000;
}
.car-description__image svg .blue {
    fill: #5364ff;
}
.car-description__image svg .blue2 {
    fill: #3c4ac1;
}
.car-description__name {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.408px;
    margin: 0 0 4px 12px;
}
.car-description__weight {
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.408px;
    margin-left: 12px;
}

.car-info {
    padding-top: 6px;
}
.car-info__item {
    display: flex;
}
.car-info__item:not(:last-child) {
    margin-bottom: 4px;
}
.car-info__legend {
    flex: 0 0 20px;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: -0.408px;
    color: #888e99;
    text-align: center;
    margin-right: 4px;
}
.car-info__value {
    background: #ffffff;
    border-radius: 4px;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.408px;
    padding: 2px 4px;
}
