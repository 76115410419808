.select-company-input{
    background: #ECEDEF;
    border-radius: 8px;
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 16px;
    cursor: pointer;
}


.select-company-rows{
    display:none;
}
.select-company-rows.visible{
    position: relative;
    background: #fff;
    width: 100%;
    margin-top: -15px;
    border: 1px solid #ecedef;
    border-radius: 0 0 10px 10px;
    display: block;
}

.select-company-item:hover{
    background:#fafafa
}
.select-company-item{
    padding: 15px 10px;
    border-bottom: 1px solid #ecedef;
    cursor: pointer;
}

.select-company-item.selected{
    color: #5364ff;
}

.select-company-item:last-child{
    border-bottom: unset;
}

.select-company-input-inn .name{
    overflow:hidden;
    height:45px;
    margin-top: -5px;

}
.select-company-input-inn div{
    width:100%;

}


.select-company-input-inn   .react-dadata__suggestions{
    margin-left: -16px;
    width: calc(100% + 32px);
    margin-top: -14px;
    margin-bottom: 40px;
}

.select-company-input-inn span.name{
    display:flex;
    justify-content: center;
    align-items: center;
    width: 55% !important;
    right: 1rem !important;
}

.select-company-input-inn svg{
    top:1rem !important;
    right: -10px !important;

}
