.help-window-title{
    padding:37px 24px;
    font-size: 21px;
    line-height: 25px;
    letter-spacing: -0.408px;

    color: #000000;

    display: flex;
    justify-content: space-between;
    align-items: end;
    min-height: 109px;
}

.help-window-back{
    font-size: 16px;
    padding: 5px 20px;
    background: #f0f0f0;
    border-radius: 20px;
    cursor: pointer;
}

.screen_help_list{
    font-size: 16px;
}

.screen_help_article{

    cursor: pointer;
    padding: 10px;
}

.screen_help_article_title{
    font-size: 18px;
    background:#eee;
    padding:12px;
}
.screen_help_article_footer{
    padding:12px;
    padding-top:0px;
    text-align: right;
}
.screen_help_article_footer,
.screen_help_article_description{
    border-left: 5px solid #eee;
    border-right: 5px solid #eee;
}
.screen_help_article_footer{
    border-bottom: 5px solid #eee;
}
.screen_help_article_description{
    padding:12px;
}


.article_window{
    position: fixed;
    width: 80%;
    background: #fff;
    top: 10%;
    height: 80%;
    left: 10%;
    z-index:50;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.article_window_content{
    padding: 24px;
    height:100%;
    overflow: auto;
}
.article_window_header{
    padding: 24px;
    font-size: 20px;
    background: #eee;
    border-radius: 5px 5px 0px 0px;
}

.window_close_btn{
    cursor: pointer;
}
