.balance-window{
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 500;
    height:100%;
    display:flex;
    flex-direction:column;
    overflow-y: auto;
}
.balance-window-title{
    padding:37px 24px;
    font-size: 21px;
    line-height: 25px;
    letter-spacing: -0.408px;

    color: #000000;
}

.balance-window-menu{
    display: flex;
    justify-content: space-between;
    padding:12px
}


.balance-window-menu-item{
    width:33%;
    font-size: 15px;
    line-height: 17px;
    color: #888E99;
    text-align: center;
    letter-spacing: -0.408px;
    padding:10px 0;
    cursor: pointer;
}

.balance-window-menu-item.selected{
    background: #ECEDEF;
    border-radius: 8px;
    color: #000;
}

.balance-window-content{
    height:100%;
    background: #ECEDEF;
    width:100%;
    padding:12px;
}
