.userRegistration {
    padding: 16px;
    background: #FFFFFF;
    border-top: 1px solid #888E99;
    min-height: 272px;
}


.FIOWrapper, .phone, .checkCode, .emailWrapper {
    background: #ECEDEF;
    border-radius: 8px;
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
}

.userRegistration input {
    background: none;
    border: none;
    width: 100%;
    outline: none;
}

.phoneWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.checkCode {

    width: 50%;
    margin-left: 10px;
}
.hideCode {
    display: none;
}

.acceptWrapper img{
    cursor: pointer;
}
.acceptWrapper {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    padding-top: 10px;
    font-size: 14px;
}
.acceptWrapper div {
    padding-left: 16px;
}
.acceptWrapper a {
    color: #000000;
    text-decoration: underline ;
}
