.select-tariff {
    padding: 0px 10px;
}

.select-tariff__header{
    display :flex;
    margin-bottom: 10px;
}
.select-tariff__header-item.selected{
    color: #5364FF;
    border-bottom: 3px solid;


}
.select-tariff__header-item{
    width:50%;
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #888E99;
    text-align: center;
    letter-spacing: -0.408px;
    padding: 17px 0;
    cursor: pointer;
}
